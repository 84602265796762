<template>
  <section class="section srv-catalog">
    <h2>Отчеты и координация услуг</h2>
    <div class="srv-catalog__container">
      <div class="srv-catalog__content">
        <div
          class="srv-catalog__categories"
          :class="{ 'srv-catalog__categories--active': childCategories && childCategories.length }"
          v-if="selectedReports && selectedReports.length"
        >
          <div class="srv-catalog__categories-container">
            <div class="srv-catalog__wrap-container srv-catalog__wrap-container--parent">
              <ul class="srv-catalog__wrap">
                <li v-for="(item, index) in reportsCategories" :key="index">
                  <a
                    @click.prevent="showReports(item.id)"
                    :class="{ active: isCategoryActive(item.id, true) }"
                    class="srv-catalog__item link"
                    href="#"
                  >
                    <span>{{ item.title }}</span>
                    <i class="icon__right">
                      <ArrowRightIcon />
                    </i>
                  </a>
                </li>
              </ul>
              <Multiselect
                track-by="id"
                label="title"
                class="srv-catalog__ms"
                :value="selectedCategory"
                :options="reportsCategories"
                @input="showReports($event.id)"
                placeholder="Выберите из списка"
                selectLabel="Выбрать ↵"
                deselectLabel="Удалить ↵"
                selectedLabel="Выбрано"
                :searchable="true"
                :allow-empty="false"
              >
                <div class="srv-catalog__ms-label" slot="singleLabel" v-if="selectedCategory">
                  <component
                    :is="selectedCategory.icon"
                    class="srv-catalog__icon"
                    v-if="selectedCategory.id === -1"
                  />
                  <i
                    class="srv-catalog__ms-icon"
                    v-else-if="selectedCategory.icon"
                    v-html="selectedCategory.icon"
                  ></i>
                  <span class="srv-catalog__ms-title">{{ selectedCategory.title }}</span>
                </div>
                <span slot="noOptions">Список пуст</span>
              </Multiselect>
            </div>
          </div>
          <ul class="srv-catalog__wrap" v-if="selectedReports && selectedReports.length">
            <li v-for="(item, index) in selectedReports" :key="index">
              <router-link
                :to="{ name: 'messages_item', params: { id: item.id, url: item.link } }"
                class="srv-catalog__link link"
              >
                <div class="srv-catalog__title">{{ item.title }}</div>
              </router-link>
            </li>
          </ul>
        </div>
        <p v-else class="_item__text">Отчетов нет</p>
      </div>
      <Banners />
    </div>
  </section>
</template>

<script>
import ArrowRightIcon from "@/components/svg/ArrowRightSmall.vue";
import Banners from "@/views/services/components/Banners.vue";
import Multiselect from "vue-multiselect";

export default {
  name: "ServicesCategoriesComponent",
  components: {
    ArrowRightIcon,
    Multiselect,
    Banners,
  },
  data() {
    return {
      selectedCategory: null,
      childCategories: [],
      selectedReports: [],
    };
  },
  mounted() {
    if (this.reportsCategories && this.reportsCategories.length) {
      this.showReports(this.reportsCategories[0].id);
    }
  },
  computed: {
    /**
     * Возвращать только категории с услугами
     */
    reportsCategories() {
      return this.$store.state.sr_page?.report_news_categories;
    },
  },
  methods: {
    showReports(id) {
      this.selectedCategory = this.reportsCategories.find((item) => item.id === id);
      if (this.selectedCategory.report_news) {
        this.selectedReports = this.selectedCategory.report_news;
      }
    },
    isCategoryActive(id) {
      if (this.selectedCategory) {
        return id === this.selectedCategory.id;
      }
      return false;
    },
  },
};
</script>

<style lang="stylus">
@import '~vue-multiselect/dist/vue-multiselect.min.css'
</style>
