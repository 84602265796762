<template>
  <section class="section">
    <div class="row">
      <div class="col-6">
        <h2>Аналитика заголовок</h2>
      </div>
      <div class="col-6">
        <h2>Аналитика заголовок</h2>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ServicesAnalyticsComponent",
};
</script>
