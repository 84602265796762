<template>
  <main class="main">
    <div class="container">
      <div class="main__inner">
        <ServicesReportsComponent />
        <!--        <ServicesAnalyticsComponent />-->
      </div>
    </div>
  </main>
</template>

<script>
import ServicesReportsComponent from "@/views/services/services_reports/components/ServicesReportsComponent.vue";
import ServicesAnalyticsComponent from "@/views/services/services_reports/components/ServicesAnalyticsComponent.vue";

export default {
  name: "ServicesReportsPage",
  async asyncData({ store }) {
    await store.dispatch("GET_SERVICES_REPORTS_PAGE");
  },
  components: {
    ServicesReportsComponent,
    ServicesAnalyticsComponent,
  },
  metaInfo() {
    return this.$seo(
      "common",
      "Официальный сайт администрации города Махачкалы",
      "",
      "",
      "Официальный сайт администрации города Махачкалы",
      "",
      ""
    );
  },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/services/index.styl"
</style>
